import { createStore } from "vuex";
const publicPath = process.env.BASE_URL;

const store = createStore({
  state() {
    return {
      isMobile: false, //Detects Mobile Devices
      isActiveRouteName: "welcome",
      isActiveRoute: publicPath,
      // PopUpthings
      isMenuClass: String,
      isLobbyOpen: false,
      isPeopleOpen: false,
      isHelpDeskOpen: false,
      isUserSettingsOpen: false,
      isNotificationOpen: false,
      isMenuOpen: false,
      isSearchOpen: false,
      isPoliteLoad: true,
      isScreenState: null,

      resetController: function() {
        this.isPeopleOpen = false;
        this.isHelpDeskOpen = false;
        this.isUserSettingsOpen = false;
      },

      isOverlayOpen: false,
      isFullScreenOpen: false,
      isShareOpen: false,
      isLiveStreamCamOn: false,
      isLiveStreamMicOn: false,
      isBrandChatOpen: false,
      // DATA
      campName: String,
      campPath: String,
      campButtons: false,
      campButtonsAlternative: false,
      camp_1:"Vorankündigung",
      camp_2:"Heute",
      camp_3:"Begleitend",

      forceTestDay: false,
      originalAdsDataObject: [],
      adsDataObject: [],
      activeComponent: null,
      activeURL: String,
      isAdsMenuOpen: false,

      scrollToHref: "hi mama",
      // LOGINSTUFF
      isLoggedIn: false,
      isLoginOpen: false,
      isLogingNow: false,
      // LIVESTREAM
      isNextLiveStream: false,

      authUser: {},

      lastNext: null,
    };
  },
  mutations: {
    setIsBrandChatOpen(state, payload) {
      state.isBrandChatOpen = payload;
    },
    setIsMobile(state, payload) {
      state.isMobile = payload;
    },
    setActiveRoute(state, payload) {
      state.isActiveRoute = payload;
    },
    setActiveRouteName(state, payload) {
      state.isActiveRouteName = payload;
    },
    setMenuState(state, payload) {
      state.resetController();
      state.isMenuOpen = payload;
    },
    setAdsMenuState(state, payload) {
      state.resetController();
      state.isAdsMenuOpen = payload;
    },
    setPeopleState(state, payload) {
      state.resetController();
      state.isPeopleOpen = payload;
    },
    setHelpDeskState(state, payload) {
      state.resetController();
      state.isHelpDeskOpen = payload;
    },
    setUserSettingsOpen(state, payload) {
      state.resetController();
      state.isUserSettingsOpen = payload;
    },
    setOverlayState(state, payload) {
      state.isOverlayOpen = payload;
    },
    setScrollToHref(state, payload) {
      state.scrollToHref = payload;
    },
    // LOGINSTUFF
    setIsLoggedIn(state, payload) {
      state.isLoggedIn = payload;
    },
    setIsLogging(state, payload) {
      state.isLogging = payload;
    },
    setAuthUser(state, payload) {
      state.authUser = payload;
    },
    setLogInOpen(state, payload) {
      state.isLoginOpen = payload;
    },
    setLastNext(state, payload) {
      state.lastNext = payload;
    },
    setNextLiveStream(state, payload) {
      state.isNextLiveStream = payload;
    },
    setLiveStreamCam(state, payload) {
      state.isLiveStreamCamOn = payload;
    },
    setLiveStreamMic(state, payload) {
      state.isLiveStreamMicOn = payload;
    },
    setNotificationOpen(state, payload) {
      state.isNotificationOpen = payload;
    },
    setScreenState(state, payload) {
      state.isScreenState = payload;
    },
    setPoliteLoad(state, payload) {
      state.isPoliteLoad = payload;
      // console.log(payload);
    },
    setAdsDataObject(state, payload) {
      state.adsDataObject = payload;
      state.originalAdsDataObject = payload;
      // console.log(payload);
    },

    setTestDay(state, payload) {
      state.forceTestDay = payload;
    },
    setTestDayFalse(state, payload) {
      state.forceTestDay = payload;
    },
    setActiveComponent(state, payload) {
      state.activeComponent = payload;
    },

    setCampOptions(state, payload) {
      state.campName = payload.campName;
      state.campPath = payload.campPath;
      state.campButtons= payload.campButtons
    },
    setCampButtonsAlternative(state, payload) {
      state.campButtonsAlternative = true;
      state.camp_1 = payload.camp_1;
      state.camp_2 = payload.camp_2;
      state.camp_3 = payload.camp_3;

      console.log("payload", payload)
    },
  },
});

export default store;
